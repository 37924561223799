import React, { useEffect } from 'react';
import { request } from '../helpers/request';

import { Buffer } from 'buffer';

const OnlineProvider = (props) => {


    useEffect(() => {
        let isSubscribed = true

        async function getStateData() {
            try {

                const startTime = new Date().getTime();

                const p = new URLSearchParams({
                    q: Buffer.from(window.location?.href).toString('base64'),
                })

                const res = await request(
                    'get',
                    `/state?${p.toString()}`,
                )

                if (isSubscribed) {
                    console.log(res)
                }

                const end_time = new Date().getTime();

                const p1 = new URLSearchParams({
                    q: Buffer.from(window.location?.href).toString('base64'),
                    ms: end_time - startTime
                })
                setTimeout(async () => {
                    await request(
                        'get',
                        `/state?${p1.toString()}`,
                    )
                }, 1000);


            } catch (error) {
                console.log(error)
            }
        }

        let intervalId = null

        if (process.env.NODE_ENV === 'production') {
            intervalId = setInterval(() => {
                getStateData()
            }, 7000); // Interval setiap 1000ms (1 detik)
        }


        // Membersihkan interval saat komponen dilepas
        return () => {
            if (intervalId) clearInterval(intervalId);
            isSubscribed = false
        };
    }, [])

    return <React.Fragment>{props.children} </React.Fragment>
}
export default OnlineProvider