import { ConnectedRouter } from 'connected-react-router'
import React, { Component } from 'react'
import { CradleLoader } from 'react-loader-spinner'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import RoutePrivate from './components/RoutePrivate'
import RoutePublic from './components/RoutePublic'
import history from './module/history'

// import LayOutQr from 'src/components/LayOutQr';

import { GetSetting } from './store/actions/getSetting'
// import treeChanges from 'tree-changes'
// import { SuccesToast } from './components/toaster'
// const loading = <div className="pt-3 text-center"></div>
export const SupendLoading = () => (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const LoadingW = ({ status }) => {
  return (
    <div className="container">
      <div className="row centered">
        {status === 'running' && <i>Pengecekan Server..</i>}
        {status === 'error' && <strong>Server galat</strong>}
        <CradleLoader arialLabel="loading-indicator" />
      </div>
    </div>
  )
}

const DefaultLayout = React.lazy(() => import(/* webpackPrefetch: true */ './layout/DefaultLayout'))
/* Login Lama */
const Login = React.lazy(() => import(/* webpackPrefetch: true */ './views/pages/login/Login'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() =>
  import(/* webpackPrefetch: true */ './views/pages/page404/Page404'),
)

// import { SocketIoClient } from 'src/module/helpers';

class App extends Component {
  constructor(props) {
    super(props)
    const { dispatch, users } = props
    const {
      app_setting: { response },
    } = props

    if (users.isAuthenticated) {
      dispatch(GetSetting())
    }
    if (response && response === 'Unauthorized') {
      localStorage.setItem('authToken', '')
    }

    this.state = {
      isShowErrorMessage: false,
    }
  }

  retryGetSetting = () => {
    const { dispatch } = this.props
    dispatch(GetSetting())
  }

  componentDidMount() {
    //
    // import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
    // import 'filepond/dist/filepond.min.css'
    import('filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css').then(() => {
      console.log('CSS telah dimuat')
    })
    import('filepond/dist/filepond.min.css').then(() => {
      console.log('CSS telah dimuat')
    })
    import('./scss/style.scss').then(() => {
      console.log('CSS telah dimuat')
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.app_setting !== prevProps.app_setting) {
      if (this.props.app_setting.status === 'error') {
        this.setState({ isShowErrorMessage: true })
        setTimeout(() => {
          this.retryGetSetting()
        }, 5000)
      }
      if (this.props.app_setting.status === 'success') {
        // alert('tekan keneh')
        this.setState({ isShowErrorMessage: false })
      }
    }
  }

  render() {
    const { users } = this.props
    const { isShowErrorMessage } = this.state

    return isShowErrorMessage ? (
      <LoadingW status={this.props.app_setting.status} />
    ) : (
      <React.Fragment>
        <ConnectedRouter history={history}>
          <React.Suspense fallback={SupendLoading}>
            <Switch>
              <RoutePublic
                isAuthenticated={users?.isAuthenticated}
                path="/login"
                exact
                component={Login}
                appSetting={this.props.app_setting.response}
                key="login"
              />
              {/* <RoutePublic
                isAuthenticated={users?.isAuthenticated}
                path="/register"
                exact
                component={Register}
                key="login"

              /> */}

              <RoutePrivate
                isAuthenticated={users?.isAuthenticated}
                path="/"
                component={DefaultLayout}
                appSetting={this.props.app_setting.response}
                name="Home"
                to="/login"
              />

              {/* {users.isAuthenticated && (
              <Route path="/" name="Home" render={(props) => <DefaultLayout appSetting={this.props.app_setting.response}  {...props} />} />
            )} */}

              {!users.isAuthenticated && (
                <RoutePrivate
                  isAuthenticated={users.isAuthenticated}
                  component={Login}
                  path="/:home"
                />
              )}
              <Route path="*">
                <Page404 />
              </Route>

              {/* <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} /> */}
            </Switch>
          </React.Suspense>
        </ConnectedRouter>
      </React.Fragment>
    )
  }
}

const toManUse = ({ response, status }) => {
  return {
    response,
    status,
  }
}

function mapStateToProps(state) {
  return {
    users: state.authentication,
    app_setting: toManUse(state.app_setting),
  }
}

export default connect(mapStateToProps)(App)
