import fetch from 'isomorphic-unfetch'

export const config = {
  baseApi:
    process.env.NODE_ENV === 'development'
      ? 'http://127.0.0.1:3333/api'
      : // : 'https://api-primary.tunasmultidata.id/api',
      'https://api.ihore.id/api',
  baseUrl:
    process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:3333' : 'https://api.ihore.id',
  // 'https://api-primary.tunasmultidata.id',
}

export const controller = new AbortController()

export const signal = controller.signal

export const GetToken = () => {
  let tmp = localStorage.getItem('authToken')
  try {
    // console.log(tmp)
    tmp = tmp && JSON.parse(tmp)
    return tmp
  } catch (error) {
    return null
  }
}

async function generateResFromFetch(res) {
  try {
    const a1 = await res.json()
    return a1
  } catch (error) {
    return null
  }
}

export const request = (method, url, data, responseType, signalController) => {
  const token = GetToken()
  const _url = `${config.baseApi}${url}`
  /* ganti loginnya agar 1 jam menit saja */
  // console.log(_newToken)

  let _data = data
  // console.log(data)
  /* 
      menunggu yang menggunakan blob :(
    */
  //  console.log(responseType)
  const isFormData = data instanceof FormData

  if (isFormData) {
    _data = Array.from(data.entries()).reduce(
      (memo, pair) => ({
        ...memo,
        [pair[0]]: pair[1],
      }),
      {},
    )
    // console.log(_data)
  }

  return fetch(_url, {
    method,
    signal: signalController?.signal || signal,
    // mode: responseType === 'blob' ? 'no-cors' : 'cors',
    credentials: 'include',
    // credentials: 'same-origin',
    // credentials: 'include'
    // redirect: 'manual',
    headers: new Headers({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...(token && {
        Authorization: 'Bearer ' + token?.token,
      }),
    }),
    // headers: {

    //   Authorization: `${token ? 'Bearer ' + token.token : null}`,
    // },
    ...(data && {
      body: JSON.stringify(_data),
    }),
  })
    .then(async (res) => {
      const a1 =
        responseType && responseType === 'blob' ? await res.blob() : await generateResFromFetch(res)
      // console.log(a1)
      if (res.ok) {
        return {
          data: a1,
        }
      }
      // eslint-disable-next-line no-throw-literal
      throw {
        response: {
          statusText: res.statusText,
          data: a1,
          status: res.status,
        },
      }
    })

    .catch((err) => {
      throw err
    })
  // console.log(data);
  // return axios({
  //   method,
  //   baseURL: config.baseApi,
  //   url,
  //   data,
  //   headers: { Authorization: `${token ? 'Bearer ' + token.token : null}`,  },
  //   // options are 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
  //   options: {
  //     responseType: responseType || 'json'
  //   },
  //   onUploadProgress
  // }).then(res => res)
  //   .catch(async (err) => {
  //     // alert(JSON.stringify(err.response))
  //     // console.log(err)
  //     throw err;
  //   });
}
