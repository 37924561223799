import { applyMiddleware, createStore, compose, combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootSaga from './sagas/index';
import rootReducer from './reducers/index';
import middleware, { sagaMiddleware } from './middleware';
import history from 'src/module/history';

const reducer = persistReducer(
    {
        key: 'billing-sks', // key is required
        storage, // storage is now required
        whitelist: ['Profile', 'authentication', 'set'],
    },
    combineReducers({ ...rootReducer, router: connectRouter(history) })
);


const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/* istanbul ignore next */
const configStore = (initialState = {}) => {
    const store = createStore(
        reducer,
        initialState,
        composeEnhancer(applyMiddleware(...middleware))
    );

    sagaMiddleware.run(rootSaga);

    if (module.hot) {
        module.hot.accept('./reducers/index', () => {
            store.replaceReducer(require('./reducers/index').default);
        });
    }

    return {
        persistor: persistStore(store),
        store,
    };
};

const { store, persistor } = configStore();

global.store = store;

export { store, persistor };


